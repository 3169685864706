import React, { useContext, useMemo } from 'react'
import {Accordion, AccordionItem, Button} from '@nextui-org/react'
import {RxDashboard} from 'react-icons/rx'
import {FiArrowLeft, FiLogOut} from 'react-icons/fi'
import {BsPeopleFill} from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'
import {HiHome} from 'react-icons/hi'
import { AuthContext } from '../../context/auth'

const Sidebar = () => {
  const {logout} = useContext(AuthContext);
  const location = useLocation();
  const pathname = location.pathname;

  const teams = useMemo(() => [
      {
          icon: HiHome,
          label:'Бүртгүүлсэн',
          activate: pathname === '/dashboard/requested',
          href: '/dashboard/requested'
      },
      {
        icon: HiHome,
        label:'Эрээнд хүлээж авсан',
        activate: pathname === '/dashboard/ereen',
        href: '/dashboard/ereen'
      },
      {
        icon: HiHome,
        label:'Монголд ирсэн',
        activate: pathname === '/dashboard/mng',
        href: '/dashboard/mng'
      },
      {
        icon: HiHome,
        label:'Ачаа олгох',
        activate: pathname === '/dashboard/olgolt',
        href: '/dashboard/olgolt'
      },
      {
        icon: HiHome,
        label:'Олгосон ачаа',
        activate: pathname === '/dashboard/olgoson',
        href: '/dashboard/olgoson'
      },
      {
        icon: HiHome,
        label:'Устгасан ачаа',
        activate: pathname === '/dashboard/delete',
        href: '/dashboard/delete'
      },
  ], [pathname]);

  const delivery = useMemo(() => [
    {
        icon: HiHome,
        label:'Бүртгүүлсэн',
        activate: pathname === '/dashboard/deliveryreq',
        href: '/dashboard/deliveryreq'
    },
    {
      icon: HiHome,
      label:'Баталгаажсан',
      activate: pathname === '/dashboard/deliveryapp',
      href: '/dashboard/deliveryapp'
    },
    {
      icon: HiHome,
      label:'Хүргэлтээр авсан',
      activate: pathname === '/dashboard/deliveryconfirm',
      href: '/dashboard/deliveryconfirm'
    },
    
], [pathname]);


  return (
    <div className='bg-white rounded-lg m-2 shadow-lg font-Roboto h-full'>
      <div>
        <div className='flex items-center p-2 justify-between hover:bg-gray-200 hover:rounded-t-md cursor-pointer'>
         <Link to="/dashboard" className='flex items-center gap-4'>
            <img className='rounded-lg h-14' src='../../logo.jpg'/>
            <div>
              <h1 className='font-semibold text-sm'>Мөрөөдөл карго</h1>
              <p className='text-xs'>Giilen.mn</p>
            </div>
         </Link>
          <FiArrowLeft/>
        </div>
        <div className='mx-4 mt-8'>
          <h1 className='text-gray-600 mb-4'>Menu</h1>
          <Link to="/dashboard" className={pathname === "/dashboard" ? 'flex items-center gap-4 bg-blue-700 text-white rounded-lg p-2 pl-4 cursor-pointer hover:bg-blue-600':
          'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
            <RxDashboard size={20}/>
            <h1 className='text-sm'>Нүүр хуудас</h1>
          </Link>

          <Link to="/dashboard/users" className={pathname === "/dashboard/users" ? 'flex items-center gap-4 bg-blue-700 text-white rounded-lg p-2 pl-4 cursor-pointer hover:bg-blue-600 mt-1':
          'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
            <RxDashboard size={20}/>
            <h1 className='text-sm'>Сайтын хэрэглэгчид</h1>
          </Link>

          <Link to="/dashboard/lesson" className={pathname === "/dashboard/lesson" ? 'flex items-center gap-4 bg-blue-700 text-white rounded-lg p-2 pl-4 cursor-pointer hover:bg-blue-600 mt-1':
          'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
            <RxDashboard size={20}/>
            <h1 className='text-sm'>Сургалт</h1>
          </Link>

          <Accordion className=' rounded-lg text-sm mt-1' isCompact>
            <AccordionItem title="Захиалга" className=''>
              {
                teams.map((data, index) => {
                  return(
                    <Link key={index} to={data.href} className={data.activate ? 'flex items-center gap-4 pl-4 bg-blue-700 hover:bg-blue-600 text-white p-2 rounded-lg cursor-pointer mt-1'
                    : 'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
                      <BsPeopleFill/>
                      <h1 className=''>{data.label}</h1>
                    </Link>
                  )
                })
              }
            </AccordionItem>
          </Accordion>

          <Accordion className=' rounded-lg text-sm mt-1' isCompact>
            <AccordionItem title="Хүргэлт" className=''>
              {
                delivery.map((data, index) => {
                  return(
                    <Link key={index} to={data.href} className={data.activate ? 'flex items-center gap-4 pl-4 bg-blue-700 hover:bg-blue-600 text-white p-2 rounded-lg cursor-pointer mt-1'
                    : 'flex mt-1 items-center gap-4 pl-4 p-2 rounded-lg hover:bg-gray-200 cursor-pointer'}>
                      <BsPeopleFill/>
                      <h1 className=''>{data.label}</h1>
                    </Link>
                  )
                })
              }
            </AccordionItem>
          </Accordion>

        </div>
        <div className='mx-4 mt-40 bottom-10 w-52'>
          <Button onClick={logout} className='flex items-center justify-start gap-4 bg-gray-200 rounded-lg p-2 pl-4 cursor-pointer hover:bg-gray-300 w-full'>
            <FiLogOut size={20}/>
            <h1 className='text-sm'>Гарах</h1>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar

