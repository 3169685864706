import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Input, Spinner} from "@nextui-org/react";
import { GET, POST } from "../../../../utils/requests";
import { lesson_uri } from "../../../../utils/url";
import { toast } from "react-toastify";

export default function LessonDelete({callback, datas}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);

  const handleCall = () => {
      callback();
  }

  const Sub = async()=> {
    setLoad(true);
    try{
        const res = await GET(lesson_uri+`/delete?id=${datas.id}`);
        if(res.status === 200){
            onOpenChange(false);
            toast.success("Амжилттай");
            handleCall();
        }
        setLoad(false);
    }catch(err){
        setLoad(false);
        console.log(err);
    }
  }

  return (
    <>
      <Button onPress={onOpen}>Устгах</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Сургалт устгах уу ?</ModalHeader>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {
                    load ?
                    <Button color="primary">
                        <Spinner color="white"/>
                    </Button>
                    :
                    <Button color="primary" onPress={Sub}>
                        Тийм
                    </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
