import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input, Textarea} from "@nextui-org/react";
import { POST } from "../../../../utils/requests";
import { delivery_uri } from "../../../../utils/url";
import { toast } from "react-toastify";

export default function ConfRejectModal({callback, data}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [explanation, setExplanation] = useState("");

  const handlecal = () => {
    callback();
  }

  const Sub = async () => {
    setLoad(true);
    if(explanation === ""){
      return toast.error("Та тайлбарыг заавал оруулна уу !");
    }
    try{
        const res = await POST({uri:delivery_uri+'/reject', data:{id: data.id, phone_no: data.user.phone_no, explanation:explanation}});
        if(res.status === 200){
          setLoad(false);
          setExplanation("");
          toast.success("Амжилттай");
          onOpenChange(false);
          handlecal();
        }
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

//   const callOpen =()=>{
//     onOpen();
//     Get();
//   }

  return (
    <>
      <Button onPress={onOpen}>Хүргэлт цуцлах</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior='inside' size="md">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody>
                <div className="font-Roboto mt-6">
                    <h1 className="m-2 uppercase font-bold text-center">Та цуцлахдаа итгэлтэй байна уу ?</h1>
                    <h1 className="mt-4">Тайлбар оруулах</h1>
                    <Textarea onChange={(e) => setExplanation(e.target.value)} value={explanation} className="mt-1 ring-1" placeholder="Тайлбар оруулах"/>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Болих
                </Button>
                {
                  load?
                  <Button color="primary">
                    <Spinner color="white"/>
                  </Button>
                  :
                  <Button color="primary" onPress={Sub}>
                    Хүргэлт цуцлах
                  </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}