import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { order_uri } from "../../../../utils/url";
import {Input, Spin} from "antd";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";


import TextArea from "antd/es/input/TextArea";

export default function TrackDeleteModal({datas, callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);

  const call = () =>{
    callback();
  }

  const Sub = async()=>{
    setLoad(true);
    try{
        const res = await axios.get(order_uri+'/delete'+`?id=${datas.id}`);
        if(res.status === 200){
            toast.success("Амжилттай");
            call();
            onOpenChange(false);
            setLoad(false);
        }
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

  return (
    <>
      
      {/* <Button size='sm' onPress={onOpen} className="ml-2">Ачаа бүртгэх</Button> */}
      <div className="bg-gray-300 p-1 rounded-md hover:bg-gray-400 cursor-pointer" onClick={onOpen}>
        <MdDeleteOutline color="white" size={24}/>
      </div>
      <Modal placement="top" isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 border-b">Ачаа устгах</ModalHeader>
              <ModalBody>
                <div className="">
                  <h1>Та {datas.trackcode} барааг устгах уу ?</h1>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Хаах
                </Button>
                {
                    load?
                    <Spin/>
                    :
                    <Button color="primary" onPress={Sub}>
                        Тийм
                    </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
