import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input, Spinner } from '@nextui-org/react'
import { GET, POST } from '../../../utils/requests';
import Paginations from '../../../components/Pagination';
import { order_uri } from '../../../utils/url';
import {TYPES} from '../../../utils/types'
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from 'react-toastify';
import Tailan from '../../../components/Tailan';



const AchaaOlgoh = () => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({limit:20, all:'', totalPage:'',phone_or_trackcode:'',barcode:''});
  const [pay, setPay] = useState({onepay:'', allpay:''});
  const [page, setPage] = useState(1);
  const [checkedValue, setValue] = useState([]);
  const [checkedTracks, setCheckedTracks] = useState([]);
  const [checkload, setCheckload] = useState(false);

  useEffect(() => {
    Get();
  }, [page]);

  const Get = async () => {
    setLoad(true);
    const b = await GET(order_uri+`/list-new?page=${page}&limit=${pagination.limit}&status=${TYPES.IRSEN}&phone_or_trackcode=${pagination.phone_or_trackcode}&barcode=${pagination.barcode}`);
    setData(b.data.data);
    setPagination({...pagination, all:b.data.all, totalPage:b.data.totalPage});
    setLoad(false);
  };

  const Sub = async () => {
    setCheckload(true);
    const b = await POST({uri: order_uri+'/olgolt', data:checkedTracks});
    if(b.status === 200){
      setCheckedTracks([]);
      toast.success("Амжилттай");
      Get();
    }else{
      toast.success("Алдаа");
    }
    setCheckload(false);
  };

  const callback =()=> {
    Get();
  }
  
  const changePage = (page) => {
    setPage(page);
  };

  function handleChange(event){
    const {value, checked} = event.target
    const a = checkedValue.includes(value);
    
    if(a){
      return
    }
      if(checked){
        setValue(pre => [...pre, value])
      }else{
        setValue(pre => {
          return [...pre.filter(skill => skill!==value)];
        })
      }
  }
  
  const handleCheckClick = (item) => {
    const track = checkedTracks.filter(it => it.id === item.id);
    if(track.length > 0){
      setData((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
      return toast.warning("Энэ бараа аль хэдийн сонгогдсон байна !");
    }
    setCheckedTracks((prevCheckedTracks) => [...prevCheckedTracks, item]);
    setData((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
  };

  const handleDeleteClick = (item) => {
    const track = data.filter(it => it.id === item.id);
    if(track.length > 0){
      setCheckedTracks((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
    }else{
      setCheckedTracks((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
      setData((prevCheckedTracks) => [...prevCheckedTracks, item]);
    }
  };

  const calculateTotalPayment = () => {
    return checkedTracks.reduce((total, track) => total + parseFloat(track.payment), 0);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      Get()
    }
  };

  const handleSearch = (event) => {
    if (event.key === 1) {
      setPagination({...pagination, phone_or_trackcode:event.value, barcode: ''});
    }
    if (event.key === 2) {
      setPagination({...pagination, phone_or_trackcode:'', barcode: event.value});
    }
  };

  const isItemChecked = (itemId) => {
    return checkedValue.includes(itemId);
  };

  return (
    <div>
      <div>

        <div className="grid grid-cols-6 mr-2 mt-2 font-Roboto">
          <div className="overflow-x-auto shadow-md sm:rounded-md grid col-span-5">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden ">
                <div className='bg-[#080852] rounded-md my-2 p-4 flex justify-between items-center text-white'>
                  <h1 className='text-lg font-bold'>Ачаа олгох</h1>
                  <Tailan type={TYPES.IRSEN} name="Ирсэн ачаа"/>
                </div>
              <div className='bg-white p-4'>
                <div className='flex items-center gap-2'>
                  <input onKeyDown={handleKeyDown} size='' className='border rounded-lg mt-2 focus:border-1 focus:border-blue-600 outline-none w-full p-2 bg-gray-100' 
                  placeholder='Утсаар хайлт хийх' onChange={(e) => handleSearch({value: e.target.value, key: 1})}/>
                  <input onKeyDown={handleKeyDown} size='' className='border rounded-lg mt-2 focus:border-1 focus:border-blue-600 outline-none w-full p-2 bg-gray-100' 
                  placeholder='Бар кодоор хайлт хийх' onChange={(e) => handleSearch({value: e.target.value, key: 2})}/>
                </div>
              {/* <div className='mt-2'>
                <AllPayModal data={checkedValue} callback={callback}/>
              </div> */}
              </div>
              <div className='overflow-auto'>
                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      {/* <th scope="col" className="p-4 text-sm font-normal">
                      </th> */}
                      <th scope="col" className="p-4 text-sm font-normal">
                        #
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Утас
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Бар код
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Огноо
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Тоо
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Үнэ
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Тайлбар
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                       
                      </th>
                    </tr>
                  </thead>
                  {
                    load?
                    <div className='flex items-center justify-center  p-4'>
                      <Spinner/>
                      <h1 className=''>Уншиж байна ...</h1>
                    </div>
                    :
                    <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                      {
                        data.map((item, index) => {
                          return(
                            <tr className="hover:bg-gray-100 dark:hover:bg-gray-700" key={index}>
                                <td className="p-2 w-4 text-center">
                                  {index+1}
                                </td>
                                <td className="py-2 px-6 text-sm font-bold text-gray-900 whitespace-nowrap dark:text-white flex flex-col">
                                  <label className=''>{item.phone_no}</label>
                                </td>
                                <td className="py-2 px-6 text-md font-bold text-gray-800  dark:text-white text-overflow">{item.trackcode}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{moment(item.date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.quantity}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{Intl.NumberFormat().format(item.payment)}₮</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.explanation}</td>
                                
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow flex gap-2">
                                  {/* <OrderDetailModal prod={item} callback={callback}/> */}
                                  <div className='bg-gray-200 p-2 rounded-md hover:bg-gray-300 cursor-pointer' onClick={()=> handleCheckClick(item)}>
                                   <FaCheck size={20}/>
                                  </div>
                                </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  }
                </table>
                </div>
                {
                  data.length === 0 &&
                  <div className='bg-white pt-8 text-center flex items-center justify-center flex-col'>
                    <img className='h-24' src='../../empty.gif'/>
                    <h1 className=''>Хоосон байна...</h1>
                  </div>
                }
               <Paginations initialPage={page} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/>
              </div>
            </div>
          </div>
          <div className='grid col-span-1'>
            <div>
              <div className='rounded-md bg-gray-200 text-black mt-2 ml-1 p-4'> 
                  <div className='flex justify-between'>
                    <div className='w-full'>
                      <h1>Ачаа тоо</h1>
                      <h1 className='font-bold text-xl'>{checkedTracks.length}ш</h1>
                    </div>
                    <div className='w-full'>
                      <h1>Ачаа дүн</h1>
                      <h1 className='font-bold text-xl'>{calculateTotalPayment()}</h1>
                    </div>
                  </div>
                  {
                    checkedTracks.map((it, index) => {
                      return(
                        <div key={index} className='flex justify-between items-center ring-2 ring-white bg-green-600 rounded-sm mt-2 p-2'>
                          <div>
                            <h1>{it.phone_no}</h1>
                            <h1 className='text-gray-200 text-xs'>{it.trackcode}</h1>
                          </div>
                          <h1 className='text-gray-100'>{it.payment}</h1>
                          <MdDeleteOutline onClick={()=> handleDeleteClick(it)} className='cursor-pointer' color='red' size={20}/>
                        </div>
                      )
                    })
                  }
                  <div className='p-2'>
                    {
                      checkload?
                      <Button className='w-full'><Spinner/></Button>
                      :
                      <Button className='w-full' onClick={Sub}>Олгох</Button>
                    }
                  </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AchaaOlgoh


