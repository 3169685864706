import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { order_uri } from "../../../../utils/url";
import {Input, Spin} from "antd";
import { FiEdit } from "react-icons/fi";

import TextArea from "antd/es/input/TextArea";

export default function TrackEditModal({datas, callback}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({trackcode:datas.trackcode, phone_no:datas.phone_no, quantity:datas.quantity, payment:datas.payment, explanation:datas.explanation, id: datas.id});

  const call = () =>{
    callback();
  }

  const Sub = async()=>{
    setLoad(true);
    try{
        const res = await axios.patch(order_uri+'/update', data);
        if(res.status === 200){
            toast.success("Амжилттай");
            call();
            onOpenChange(false);
            setLoad(false);
        }
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

  return (
    <>
      
      {/* <Button size='sm' onPress={onOpen} className="ml-2">Ачаа бүртгэх</Button> */}
      <div className="bg-gray-300 p-1 rounded-md hover:bg-gray-400 cursor-pointer" onClick={onOpen}>
        <FiEdit color="white" size={24}/>
      </div>
      <Modal placement="top" isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 border-b">Ачаа бүртгэх</ModalHeader>
              <ModalBody>
                <div className="">
                  <div className="flex items-center gap-2 w-full">
                    <div className="w-full">
                      <h1>Бар код</h1>
                      <Input onChange={(e) => setData({...data, trackcode:e.target.value})} value={data.trackcode} className="mt-1" placeholder="Бар код оруулах" size="sm"/>
                    </div>
                    <div className="w-full">
                      <h1>Утасны дугаар</h1>
                      <Input onChange={(e) => setData({...data, phone_no:e.target.value})} value={data.phone_no} className="mt-1" placeholder="Утасны дугаар оруулах" type="number" size="sm"/>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full mt-2">
                    <div className="w-full">
                      <h1>Тоо хэмжээ</h1>
                      <Input onChange={(e) => setData({...data, quantity:e.target.value})} value={data.quantity} className="mt-1" placeholder="0" size="sm" type="number"/>
                    </div>
                    <div className="w-full">
                      <h1>Карго үнэ</h1>
                      <Input onChange={(e) => setData({...data, payment:e.target.value})} value={data.payment} className="mt-1" placeholder="0" size="sm" type="number"/>
                    </div>
                  </div>
                  <div className="w-full mt-2">
                    <h1>Тайлбар</h1>
                    <TextArea onChange={(e) => setData({...data, explanation:e.target.value})} value={data.explanation} className="mt-1" placeholder="Тайлбар" size="sm" />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Хаах
                </Button>
                {
                    load?
                    <Spin/>
                    :
                    <Button color="primary" onPress={Sub}>
                        Шинэчлэх
                    </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
