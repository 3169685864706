import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input} from "@nextui-org/react";
import { GET } from "../../../../utils/requests";
import { delivery_uri } from "../../../../utils/url";
import { TYPES } from "../../../../utils/types";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell} from "@nextui-org/react";
import ConfRejectModal from "./confReject";


export default function ConfDetailModal({callback, data}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [load, setLoad] = useState(false);
  const [datas, setDatas] = useState([]);
  const [allpay, setAllpay] = useState();

  const handlecal = () => {
    callback();
  }

  const Get = async () => {
    setLoad(true);
    try{
        const res = await GET(delivery_uri+`/userfind?status=${TYPES.CONFIRM}&phone_no=${data.user.phone_no}&type=${TYPES.CONFIRM}`);
        setDatas(res.data);
        setAllpay(res.data.reduce((a,v) =>  a = a + Number(v.payment) , 0 ).toLocaleString());
        setLoad(false);
    }catch(err){
        console.log(err);
        setLoad(false);
    }
  }

  const callOpen =()=>{
    onOpen();
    Get();
  }


  return (
    <>
      <Button size="sm" className="bg-gray-200 text-gray-600" onPress={callOpen}>Дэлгэрэнгүй</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior='inside' size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex justify-between gap-1 font-Roboto">
                <h1>Хүргэлтийн дэлгэрэнгүй мэдээлэл</h1>
              </ModalHeader>
              <ModalBody>
                <div className="font-Roboto">
                    <div>
                    <Table aria-label="Example static collection table">
                        <TableHeader>
                            <TableColumn>Бар код</TableColumn>
                            <TableColumn>Тоо</TableColumn>
                            <TableColumn>Карго</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {
                                datas.map((it, index) => {
                                    return(
                                        <TableRow key={index}>
                                            <TableCell>{it.trackcode}</TableCell>
                                            <TableCell>{it.quantity}</TableCell>
                                            <TableCell>{it.payment}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    {
                        load &&
                        <Spinner/>
                    }
                    <h1 className="m-2 uppercase font-bold text-end">Нийт каргоны төлбөр <span> {allpay} </span></h1>
                    </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Болих
                </Button>
                <ConfRejectModal callback={callback} data={data}/>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}